import { template as template_b410e8f69e7942e4aab731beacdaa83d } from "@ember/template-compiler";
const FKControlMenuContainer = template_b410e8f69e7942e4aab731beacdaa83d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
