import { template as template_be425446764f4baf89ad8a39a659f226 } from "@ember/template-compiler";
const FKText = template_be425446764f4baf89ad8a39a659f226(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
